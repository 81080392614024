<template>
  <div>
    <b-form-group
      :label="$t('students')"
    >
      <v-select
        v-model="studentId"
        :placeholder="$t('class-test.select-student')"
        label="fullName"
        :options="students"
        :reduce="option => option.id"
      >
        <template #option="{ fullName }">
          {{ fullName }}
        </template>
      </v-select>
    </b-form-group>
    <b-button
      v-if="studentId"
      variant="outline-primary"
      class="mb-1"
      @click="showFollow = true"
    >
      {{ $t('add-follow-up') }} <feather-icon icon="PlusIcon" />
    </b-button>
    <follow-up v-if="showFollow"
               :lesson-id="lessonId"
               :class-id="classId || $route.params.classId"
               :student-id="studentId"
               :student-info="students.find(s => s.id == studentId)"
               @close="showFollow = false"
    />

    <TeacherStudentsNote v-if="studentId"
                         :key="studentId"
                         :lesson-id="lessonId"
                         :student-id="studentId"
    />
  </div>
</template>
<script setup>
import {
  BFormGroup, BButton,
  // BFormRating,
} from 'bootstrap-vue'
import { computed, ref } from 'vue'
import vSelect from 'vue-select'
import FollowUp from '../components/FollowUp.vue'
import TeacherStudentsNote from './teacher-students-note.vue'

const props = defineProps({
  classRooms: {
    type: Array,
    default: () => [],
  },
  classId: {
    type: Number,
    default: 0,
  },
  lessonId: {
    type: Number,
    default: 0,
  },
  allStudents: {
    type: Array,
    default: () => [],
  },
})

const studentId = ref(null)
const showFollow = ref(false)
// const selectedClassrooms = ref([+props.classId])
const students = computed(() => props.allStudents.map(item => ({
  id: item.id,
  fullName: `${item?.firstname} ${item?.lastname}`,
})))

</script>
