<template>
  <portal to="dragModalTargetArea">
    <dialog-drag
      v-for="activeWord, index in activeWordsList"
      :key="index"
      style="min-width:400px;min-height:336px;position: fixed;"
      :options="{ top: vocabData[activeWord.toLowerCase()]?.options.top, left: vocabData[activeWord.toLowerCase()]?.options.left}"
      @close="$emit('close', activeWord)"
      @drag-end="dialogDragEnd($event, activeWord.toLowerCase())"
      @move="dialogDragEnd($event, activeWord.toLowerCase())"
    >
      <h5
        slot="title"
        class="m-0 text-capitalize"
      >{{ activeWord.toLowerCase() }}
      </h5>
      <b-overlay
        class="p-1"
        :set="languageOptions = getLanguageOptions(vocabData[activeWord.toLowerCase()])"
      >
        <div class="row position-relative">
          <div class="col-md-7">
            <button class="btn btn-secondary w-100">
              English
            </button>
            <div class="pt-2">
              <h5>{{ activeWord.toLowerCase() }}</h5>
              <hr>
            </div>
          </div>
          <div v-if="languageOptions.length"
               class="col-md-5"
          >
            <b-form-group>
              <b-form-select
                v-model="activeLanguage"
                :options="languageOptions"
              />
            </b-form-group>
          </div>
        </div>
        <div
          v-if="vocabData[activeWord.toLowerCase()]?.id"
          class="row"
        >
          <!-- English -->
          <vocab-card-data
            :class="vocabData[activeWord.toLowerCase()]?.other_version?.length ? 'col-7' : 'col-12'"
            :active-word="activeWord"
            :vocab-data="vocabData[activeWord.toLowerCase()]"
          />
          <!-- Other Language -->
          <vocab-card-data
            v-if="activeLanguage && getActiveLanguage(activeWord)"
            class="col-5 p-0"
            :active-word="getActiveLanguage(activeWord).vocab_word"
            :vocab-data="getActiveLanguage(activeWord)"
            :show-image="false"
          />
        </div>
        <div
          v-else
          class="p-1 position-relative"
        >
          <p class="mb-0 text-center">
            {{ $t("translation-wordnet-module.no-data-available") }}
          </p>
        </div>
      </b-overlay>
    </dialog-drag>
  </portal>
</template>

<script>
import {
  // BModal,
  BFormGroup, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import DialogDrag from 'vue-dialog-drag'
import locales from '@/const/locales'
import VocabCardData from './VocabCardData.vue'
import '@/assets/scss/custom/draggableModal.scss'

export default {
  components: {
    BOverlay,
    // BModal,
    BFormGroup,
    BFormSelect,
    VocabCardData,
    DialogDrag,
  },
  props: {
    modalKey: {
      type: String,
      default: () => '',
    },
    vocabData: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeWordsList: {
      type: Array,
      required: true,
    },
    activeTranslation: {
      type: [Object, Array, String],
      default: () => null,
    },
    activeWordNet: {
      type: [Object, String, Array],
      default: () => null,
    },
    language: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      activeLanguage: null,
      activeTranslatedWord: null,
    }
  },
  watch: {
    language: {
      handler(val) {
        if (val && val !== this.activeLanguage) this.activeLanguage = val
      },
      immediate: true,
    },
    // 'vocabData.other_version': {
    //   handler(val) {
    //     if (val && !val.length) this.activeTranslatedWord = null
    //     else this.setActiveLanguage()
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    getLanguageOptions(word) {
      const locale = localStorage.getItem('locale')
      const language = locales.find(l => l.locale === locale)?.value || 'english'
      if (!word.other_version || !word.other_version.length) {
        return []
      }
      let otherLang = [
        { text: 'Please select a language', value: null, disabled: true },
      ]
      otherLang = [...otherLang, ...Array.from(new Set(word.other_version.map(item => item.lang))).map(lang => ({
        text: lang,
        value: lang,
      })),
      ]
      if (!this.activeLanguage && otherLang.some(langOption => langOption.value === language)) {
        this.activeLanguage = language
      }
      return otherLang
    },
    getActiveLanguage(activeWord) {
      return this.vocabData[activeWord.toLowerCase()].other_version?.find(item => item.lang === this.activeLanguage)
    },
    playAudio(audioUrl) {
      const audio = new Audio(audioUrl)
      audio.play()
    },
    dialogDragEnd(obj, activeWord) {
      this.$set(this.vocabData[activeWord].options, 'left', obj.left)
      this.$set(this.vocabData[activeWord].options, 'top', obj.top)
    },
  },
}
</script>

<style scoped>
.on-hover-library:hover {
  text-decoration: underline;
}
.zoom-on-hover:hover {
  zoom: 1.5;
}
.icon-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
</style>
<style src='vue-dialog-drag/dist/vue-dialog-drag.css'></style>
