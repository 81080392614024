// eslint-disable-next-line import/no-cycle
import { isStudentYoung } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import { $themeConfig } from '@themeConfig'
import {
  STATUS,
} from '@/const/avatar'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      ui: localStorage.getItem('ui') || $themeConfig.layout.ui,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
    selectedCharacter: null,
    selectedChatbotCharacter: localStorage.getItem('selectedChatbotCharacter') || 'jp_male',
    chatbotInputType: localStorage.getItem('chatbotInputType') || 'text',
    chatbotLanguage: localStorage.getItem('chatbotInputType') || null,
    chatbotInputLanguage: localStorage.getItem('chatbotInputLanguage') || null,
    currentStatus: STATUS[0],
    showAvatar: false,
    showAvatarStudent: localStorage.getItem('showAvatarStudent') ? JSON.parse(localStorage.getItem('showAvatarStudent')) : true,
    // themeOn: typeof (JSON.parse(localStorage.getItem('themeOn'))) === 'boolean' ? JSON.parse(localStorage.getItem('themeOn')) : isStudentYoung(),
    themeOn: localStorage.getItem('themeOn') ? localStorage.getItem('themeOn') : false,
    alarmOn: JSON.parse(localStorage.getItem('alarmOn')) || false,
    refreshRewardStats: false,
    alarmAnimationState: 'idle',
    currentTheme: localStorage.getItem('currentTheme') || 'forest',
    selectedOutfits: JSON.parse(localStorage.getItem('selectedOutfits')) || {},
    isStudentYoung: isStudentYoung(),
    schoolInfo: null,
    districtInfo: null,
    justLoggedIn: false,
  },
  getters: {
    isDarkMode: state => state.layout?.skin === 'dark',
    currentCharacter: state => state.selectedCharacter,
    currentChatbotCharacter: state => state.selectedChatbotCharacter,
    currentChatbotInputType: state => state.chatbotInputType,
    currentChatbotLanguage: state => state.chatbotLanguage,
    currentChatbotInputLanguage: state => state.chatbotInputLanguage,
    currentTheme: state => state.currentTheme,
    getRefreshRewardStats: state => (state.refreshRewardStats),
    getIsStudentYoung: state => (state.isStudentYoung),
    getSchoolSettings: state => (state.schoolInfo?.user_meta),
    getDistrictSettings: state => (state.districtInfo?.user_meta),
  },
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_IS_STUDENT_YOUNG(state, value = false) {
      state.isStudentYoung = value || isStudentYoung()
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList = 'dark-layout'
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')

      if (skin === 'semi-light') document.body.classList = 'semi-light-layout'
      else if (document.body.className.match('semi-light-layout')) document.body.classList.remove('semi-light-layout')

      if (skin === 'semi-light-reverse') document.body.classList = 'semi-light-reverse-layout'
      else if (document.body.className.match('semi-light-reverse-layout')) document.body.classList.remove('semi-light-reverse-layout')

      if (skin === 'brown') document.body.classList = 'brown-layout'
      else if (document.body.className.match('brown-layout')) document.body.classList.remove('brown-layout')

      if (skin === 'green') document.body.classList = 'green-layout'
      else if (document.body.className.match('green-layout')) document.body.classList.remove('green-layout')
    },
    UPDATE_UI(state, ui) {
      state.layout.ui = ui
      // Update UI
      localStorage.setItem('ui', ui)
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_SELECTED_CHARACTER(state, val) {
      state.selectedCharacter = val
    },

    UPDATE_CURRENT_STATUS(state, val) {
      state.currentStatus = val
    },

    UPDATE_ALARM_STATUS(state, val) {
      state.alarmAnimationState = val
    },

    UPDATE_SHOW_AVATAR(state, val) {
      state.showAvatar = val
    },

    UPDATE_SHOW_AVATAR_STUDENT(state, val) {
      state.showAvatarStudent = val
    },

    SET_REFRESH_REWARD_STATS(state, data) {
      state.refreshRewardStats = data
    },
    UPDATE_THEME_ON(state, val) {
      state.themeOn = val
      localStorage.setItem('themeOn', val)
    },
    UPDATE_ALARM_ON(state, val) {
      state.alarmOn = val
      localStorage.setItem('alarmOn', val)
    },
    UPDATE_CURRENT_THEME(state, val) {
      state.currentTheme = val
      localStorage.setItem('currentTheme', val)
    },
    UPDATE_SELECTED_OUTFITS(state, val) {
      state.selectedOutfits = val
      localStorage.setItem('selectedOutfits', JSON.stringify(val))
    },

    SET_CURRENT_CHATBOT_CHARACTER(state, val) {
      state.selectedChatbotCharacter = val
      localStorage.setItem('selectedChatbotCharacter', val)
    },
    SET_CURRENT_CHATBOT_INPUT_TYPE(state, val) {
      state.chatbotInputType = val
      localStorage.setItem('chatbotInputType', val)
    },
    SET_CURRENT_CHATBOT_LANGUAGE(state, val) {
      state.chatbotLanguage = val
      localStorage.setItem('chatbotLanguage', val)
    },
    SET_CHATBOT_INPUT_LANGUAGE(state, val) {
      state.chatbotInputLanguage = val
      localStorage.setItem('chatbotInputLanguage', val)
    },
    UPDATE_SCHOOL_INFO(state, val) {
      state.schoolInfo = val
      localStorage.setItem('schoolInfo', JSON.stringify(val))
    },
    UPDATE_DISTRICT_INFO(state, val) {
      state.districtInfo = val
      localStorage.setItem('districtInfo', JSON.stringify(val))
    },
    UPDATE_JUST_LOGGED_IN(state, val) {
      state.justLoggedIn = val
    },
  },
  actions: {
    updateStatus({ commit, state }, status) {
      if (state.currentStatus === status.oldValue) {
        commit('UPDATE_CURRENT_STATUS', status.newValue)
      }
    },
    updateAvatarVisiblity({ commit }, payload) {
      commit('UPDATE_SHOW_AVATAR', payload)
    },
    setRefreshRewardStats({ commit }, data) {
      commit('SET_REFRESH_REWARD_STATS', data)
    },

    getSelectedOutfits({ commit }) {
      useJwt.getSelectedOutfits().then(res => {
        commit('UPDATE_SELECTED_OUTFITS', JSON.parse(res.data.data))
      })
    },
    updateSchoolDistrictInfo({ commit }, { schoolInfo, districtInfo }) {
      commit('UPDATE_SCHOOL_INFO', schoolInfo)
      commit('UPDATE_DISTRICT_INFO', districtInfo)
    },
  },
}
