import common from './common'

// window.IS_LANG_TRANSLATE_SCRIPT_LOADED = false
// window.isTranslationScriptCodeLoaded = false

export default function setUpGoogleTranslate(targetElement) {
  // **Clear previous Google Translate instances**
  function resetGoogleTranslate() {
    document.querySelectorAll('.goog-te-banner-frame, .goog-te-gadget').forEach(el => el.remove())

    // Remove the script tag to force reloading
    const existingScript = document.querySelector('script[src*="translate.google.com"]')
    if (existingScript) {
      existingScript.remove()
    }

    // Remove previous translate container
    const translateContainer = document.getElementById(targetElement)
    if (translateContainer) {
      translateContainer.innerHTML = ''
    }
  }

  window.googleTranslateElementInit = () => {
    if (window.isTranslationScriptCodeLoaded) return
    window.isTranslationScriptCodeLoaded = true
    const langBrowser = navigator.language || navigator.userLanguage
    const currentBLang = langBrowser.split('-')[0]

    // **Initialize Google Translate widget again**
    // eslint-disable-next-line no-new, no-undef
    new google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,ja,ko,vi,ne',
      },
      targetElement,
    )

    // **Wait for dropdown to load, then set the default language**
    setTimeout(() => {
      const selectElement = document.querySelector(`#${targetElement} select`)
      if (selectElement) {
        selectElement.value = currentBLang
        selectElement.dispatchEvent(new Event('change'))
      } else {
        console.warn('Google Translate dropdown not found')
      }
    }, 2000)
  }

  // **Remove previous instances and reload Google Translate**
  resetGoogleTranslate()

  if (!window.IS_LANG_TRANSLATE_SCRIPT_LOADED) {
    common.loadExternalCdn('//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit')
    window.IS_LANG_TRANSLATE_SCRIPT_LOADED = true
  } else {
    window.isTranslationScriptCodeLoaded = false
    window.googleTranslateElementInit()
  }

  // **Fix styling issues**
  const styleId = 'google-translate-style'
  if (!document.getElementById(styleId)) {
    const style = document.createElement('style')
    style.id = styleId
    style.textContent = `
      .skiptranslate:not(.goog-te-gadget) { display: none !important; } 
      body { top: 0 !important } 
      .skiptranslate.goog-te-gadget { visibility: hidden !important; } 
      .skiptranslate.goog-te-gadget div { visibility: visible; }
      select.goog-te-combo { padding: 4px; }
      .VIpgJd-ZVi9od-aZ2wEe-wOHMyf { display: none; }
    `
    document.head.appendChild(style)
  }
}
