<template>
  <div>
    <ckeditor
      v-model="content"
      :config="config"
      :disabled="disabled"
      :read-only="disabled"
      @blur="onEditorChange(content)"
    />
  </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue'

const uploadUrl = `${process.env.VUE_APP_GEC_API}/v1/editor/image/upload`

export default {
  components: {
    ckeditor: CKEditor.component,
  },

  props: {
    value: {
      type: String,
      default: '<span style="font-size:22px">&shy;</span>',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // eqneditor
    return {
      content: '<span style="font-size:22px">&shy;</span>',
      config: {
        allowedContent: true,
        basicEntities: true,
        entities: false,
        filebrowserUploadUrl: `${uploadUrl}`,
        filebrowserImageUploadUrl: `${uploadUrl}`,
        filebrowserUploadMethod: 'xhr',
        fileTools_requestHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
        simpleuploads_acceptedExtensions: 'jpeg|jpg|png',
        extraPlugins: 'colorbutton,font, mathjax',
        disableNativeSpellChecker: false,
        fontSize_defaultLabel: '22',
        mathJaxLib:
        'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML',
      },
    }
  },
  watch: {
    value() {
      if (this.value.length > 0) this.setPage()
    },
    content(value) {
      this.$emit('input', value)
    },
  },
  created() {
    this.setPage()
    setTimeout(() => {
      this.$emit('ready')
    }, 1000)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    // onNamespaceLoaded(CKEDITOR) {
    //   CKEDITOR.plugins.addExternal('eqneditor', '/plugins/eqneditor/plugin.js')
    // },
    // onReadyEditor(editor) {
    //   editor.ui.getEditableElement().parentElement.insertBefore(
    //     editor.ui.view.toolbar.element,
    //     editor.ui.getEditableElement(),
    //   )
    // },
    onEditorChange(html) {
      // const htmlString = html.replace(/style="([^"]*)color:\s*([^;"]+)(?:\s*!important)?/g, (match, p1, p2) => {
      //   // Check if !important is already present, if not, add it
      //   if (!/\s*!important/.test(p2)) {
      //     return `style="${p1}color: ${p2} !important"`
      //   }
      //   // Return the original match if !important is already present
      //   return match
      // })
      this.$emit('input', html)
      this.$emit('blur')
    },
    setPage() {
      if (this.value.length > 0) this.content = this.value
    },
  },
}
</script>
