<template>
  <div class="card ecommerce-card"
       :class="{
         'border-primary': isSelected
       }"
  >
    <!----><!---->
    <div v-b-tooltip.hover.top="item.queue_info === 'failed' ? 'Failed In generating image' : null"
         class="card-header course-img-wrapper d-flex justify-content-center"
         :class="item.queue_info === 'failed' ? 'bg-danger' : ''"
    >
      <div v-if="!isReadOnly"
           class="img-select-checkbox"
      >
        <b-form-checkbox :checked="isSelected"
                         @change="v => $emit('handleSelect', v)"
        />
      </div>
      <div
        href="#"
        class="w-100"
        target="_self"
      ><ImageWithSize
        :src="item.queue_info === 'on-progress' ? 'https://i.giphy.com/3oEjI6SIIHBdRxXI40.webp' :item.image"
        :image-style="{ height: '160px', width: '100%' }"
        :alt="item.image_name"
        class="card-img-top img-fluid card-img"
      /></div>
    </div>
    <div class="card-body">
      <div class="item-wrapper">
        <div><h6 class="item-price">
          {{ $t('image-library-module.word') }}:  {{ item.vocab_word }}
        </h6></div>
      </div>
      <div v-if="item.synonyms && item.synonyms.length"
           class="item-wrapper"
      >
        <div><h6 class="item-price">
          {{ $t('image-library-module.synonyms') }}:  {{ item.synonyms?.map(item => item.text).toString() }}
        </h6></div>
      </div>
      <p class="card-text item-description">
        <audio-player :key="item.audio"
                      :src="item.audio || ''"
        />
      </p>
    </div>
    <div v-if="!isReadOnly">
      <div v-if="!fromPicker"
           class="item-options text-center mb-2"
      >
        <button v-b-tooltip.hover="$t('image-library-module.remove')"
                type="button"
                class="btn btn-wishlist remove-wishlist btn-outline-danger mr-1"
                @click.stop="$emit('onDelete', item)"
        >
          <feather-icon icon="XIcon" />
        </button>
        <button v-b-tooltip.hover="$t('actions.edit')"
                type="button"
                class="btn btn-cart move-cart btn-outline-primary"
                @click.stop="$emit('onEdit', item)"
        >
          <feather-icon icon="EditIcon" />
        </button>
        <button v-b-tooltip.hover="$t('image-library-module.copy-id')"
                type="button"
                class="btn btn-cart move-cart btn-outline-primary ml-1"
                @click.stop="copyTextToClipboard(item.id)"
        >
          <feather-icon icon="ClipboardIcon" />
        </button>
        <button v-if="item.lang === 'english'"
                v-b-tooltip.hover="$t('image-library-module.translate')"
                type="button"
                class="btn btn-cart move-cart btn-outline-primary ml-1"
                @click.stop="$emit('onTranslate', item.id)"
        >
          <feather-icon icon="FilePlusIcon" />
        </button>
      </div>
      <div v-else
           class="item-options text-center mb-2"
      >
        <button v-b-tooltip.hover="`Pick`"
                type="button"
                class="btn btn-cart move-cart btn-outline-primary"
                @click.stop="$emit('onPick', item)"
        >
          <feather-icon icon="NavigationIcon" />
          <span class="ml-1">{{ $t('image-library-module.pick') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AudioPlayer from '@/views/common/components/AudioPlayer.vue'
import { BFormCheckbox, VBTooltip } from 'bootstrap-vue'
import ImageWithSize from '@/views/common/components/ImageWithSize.vue'

export default {
  components: {
    AudioPlayer,
    ImageWithSize,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    fromPicker: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    courseParams(id) {
      if (this.AUTH_USER().usertype === 'national') {
        return {
          params: { library: id },
        }
      }
      return {
        query: { library: id },
      }
    },
  },
}
</script>
<style scoped>
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
.img-select-checkbox {
  margin-left: 3px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 4px;
  cursor: pointer;
}
/* .course-img-wrapper {
  height: 160px !important;
} */
</style>
