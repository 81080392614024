/* eslint-disable import/no-cycle */
import adminUsersOnly from '@/middlewares/adminUsersOnly'
import isAuthenticated from '@/middlewares/isAuthenticated'

export default [
  // {
  //   meta: {
  //     middleware: [isAuthenticated, adminUsersOnly],
  //   },
  //   path: '/super/home',
  //   name: 'super-home',
  //   component: () => import('@/views/super/Home.vue'),
  // },
  {
    path: '/super/test',
    name: 'super-test-code',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/test/index.vue'),
  },
  {
    path: '/super/q-capture',
    name: 'super-ocr',
    component: () => import('@/views/super/ocr-test/index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/users',
    name: 'super-users',
    component: () => import('@/views/common/user/Index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/courses',
    name: 'super-courses',
    component: () => import('@/views/common/course/Index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/problem',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-problem',
    component: () => import('@/views/super/problem/Index.vue'),
  },
  {
    path: '/super/problem/create',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-problem-create',
    component: () => import('@/views/super/problem/create-problem/Index.vue'),
  },
  {
    path: '/super/problem/edit',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-problem-edit',
    component: () => import('@/views/super/problem/edit-problem/Index.vue'),
  },
  {
    path: '/super/standards',
    name: 'super-standards',
    component: () => import('@/views/super/standard/Index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/:pageId/edit-curriculum-page',
    name: 'super-edit-curriculum-page',
    component: () => import('@/views/common/curriculum/components/CurriculumPageEdit.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/:pageId/edit-lesson-page',
    name: 'super-edit-lesson-page',
    component: () => import('@/views/common/lessons/PageEdit.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/permissions',
    name: 'super-permissions',
    component: () => import('@/views/super/permissions/Index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },

  {
    path: '/super/networks',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-networks',
    component: () => import('@/views/common/network/Index.vue'),
  },
  {
    path: '/super/lesson-pages',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-lessonPages',
    component: () => import('@/views/common/lessons/Index.vue'),
  },
  {
    path: '/super/networks/:id',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-networks',
    },
    name: 'super-networks-show',
    component: () => import('@/views/common/network/Show.vue'),
  },
  {
    path: '/super/adaptive-learning',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-adaptive-learning',
    component: () => import('@/views/super/problem/adaptive-learning/Index.vue'),
  },
  {
    path: '/super/adaptive-learning/create',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-adaptive-learning',
    },
    name: 'super-adaptive-learning-create',
    component: () => import('@/views/super/problem/adaptive-learning/Create.vue'),
  },
  {
    path: '/super/adaptive-learning/edit/:id',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-adaptive-learning',
    },
    name: 'super-adaptive-learning-edit',
    component: () => import('@/views/super/problem/adaptive-learning/Edit.vue'),
  },
  {
    path: '/super/problem-template',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-problem-template',
    },
    name: 'super-problem-template',
    component: () => import('@/views/common/templates/Index.vue'),
  },
  {
    path: '/super/classes',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-classes',
    component: () => import('@/views/common/class/Handler.vue'),
  },
  {
    path: '/common/curriculum-builder',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-curriculum-builder',
    },
    name: 'curriculum-builder',
    component: () => import('@/views/common/curriculum/Index.vue'),
  },
  {
    path: '/super/classes/:id/:courseId?/:reporting?',
    name: 'super-class',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-classes',
    },
    component: () => import('@/views/common/class/Show.vue'),
  },
  {
    path: '/super/student-diary/:id',
    name: 'super-student-diary',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/common/student-diary/Index.vue'),
  },
  {
    path: '/super/class/:classId/super/:id/rubrics',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-classes',
    },
    name: 'super-rubrics',
    component: () => import('@/views/common/class/components/rubric/Show.vue'),
  },
  {
    path: '/super/courses/:id',
    name: 'super-course',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-classes',
    },
    component: () => import('@/views/common/class/components/ShowCourse.vue'),
  },
  {
    path: '/super/points-setting',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-points-setting',
    component: () => import('@/views/super/points-settings/index.vue'),
  },
  {
    path: '/student/dashboard/:id',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'student-dashboard-for-super',
    component: () => import('@/views/common/dashboard/Index.vue'),
  },
  {
    path: '/global-settings',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'global-settings',
    component: () => import('@/views/super/global-settings/index.vue'),
  },
  {
    path: '/super/game-list',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'game-lists',
    component: () => import('@/views/super/game-settings/GameList.vue'),
  },
  {
    path: '/super/game-setting',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'game-settings',
    component: () => import('@/views/super/game-settings/GameSetting.vue'),
  },
  {
    path: '/super/view-students/:id',
    name: 'super-view-students',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-classes',
    },
    component: () => import('@/views/v2/common/class/components/StudentTable.vue'),
  },
  {
    path: '/super/partner',
    name: 'super-partner',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-partner',
    },
    component: () => import('@/views/super/partner/crud/index.vue'),
  },
  {
    path: '/super/personality',
    name: 'super-personality',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-personality',
    },
    component: () => import('@/views/super/personality/Index.vue'),
  },
  {
    path: '/super/sdp-lesson/:id',
    name: 'sdp-lesson',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/sdp-problem/index.vue'),
  },
  // scorm
  {
    path: '/super/scorm/:uuid/play',
    name: 'super-scorm-player',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/scorm/player.vue'),
  },
  {
    path: '/super/scorm/:uuid/report',
    name: 'super-scorm-player-report',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/scorm/Report/index.vue'),
  },
  {
    path: '/super/scorm/manage',
    name: 'super-scorm-manager',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/scorm/crud/index.vue'),
  },
  {
    path: '/super/library',
    name: 'super-library',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/library/index.vue'),
  },
  {
    path: '/super/curriculum-builder/:library',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-curriculum-builder',
    },
    name: 'super-curriculum-builder',
    component: () => import('@/views/common/curriculum/Index.vue'),
  },
  {
    path: '/super-demo-lesson',
    name: 'super-demo-lesson',
    meta: {
      layout: 'full',
      // middleware: [isNotAuthenticated],
    },
    component: () => import('@/views/GuestLesson/index.vue'),
  },
  {
    path: '/super/curriculum-builder/:library',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-curriculum-builder',
    component: () => import('@/views/common/curriculum/Index.vue'),
  },
  {
    path: '/super/error-reports',
    name: 'super-error-reports',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/common/report-issue/Index.vue'),
  },

  //   Marketplace
  {
    path: '/super/marketplace',
    name: 'super-marketplace',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/marketplace/super/index.vue'),
  },

  //   Animated Marketplace
  {
    path: '/super/animated-items',
    name: 'animated-items',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/animation_marketplace/index.vue'),
  },

  // reservations
  {
    path: '/super/reservations',
    name: 'super-reservations',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/reservations/internal/index.vue'),
  },
  {
    path: '/super/public-reservations/:id',
    name: 'super-public-reservations',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/reservations/internal/public-requests/index.vue'),
  },
  {
    path: '/super/higher-level-report',
    name: 'super-higher-level-report',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/common/higher-level-report/index.vue'),
  },
  {
    path: '/super/individual-report/:studentId',
    name: 'super-individual-report',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/common/individual-report/index.vue'),
  },
  {
    path: '/super/image-library',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-image-library',
    component: () => import('@/views/super/image-library/index.vue'),
  },
  {
    path: '/super/ai-ide',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-ai-ide',
    component: () => import('@/views/super/ai-ide/index.vue'),
  },
  {
    path: '/super/page-links',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-page-links',
    component: () => import('@/views/super/lesson-link/index.vue'),
  },

  // videos
  {
    path: '/super/help-videos',
    name: 'super-help-videos-list',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/help-videos/Index.vue'),
  },

  // thirdparty-accounts
  {
    path: '/super/thirdparty-accounts',
    name: 'super-thirdparty-accounts-list',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@views/super/thirdparty-accounts/Index.vue'),
  },
  {
    path: '/super/thirdparty-accounts/:provider',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
      navActiveLink: 'super-thirdparty-accounts-list',
    },
    name: 'super-thirdparty-accounts-user-list',
    component: () => import('@views/super/thirdparty-accounts/UserList.vue'),
  },
  // thirdparty-accounts

  {
    path: '/super/assets-management',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-assets-management',
    component: () => import('@/views/super/assets-management/index.vue'),
  },
  {
    path: '/super/recent-activities',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    name: 'super-recent-activities',
    component: () => import('@/views/common/user/components/UsersActivity.vue'),
  },
  {
    path: '/super/calibration-setting',
    name: 'calibration-setting',
    component: () => import('@/views/super/calibration/Index.vue'),
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
  },
  {
    path: '/super/import-data',
    name: 'super-import-data',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/import-data/index.vue'),
  },
  {
    path: '/super/localization',
    name: 'super-localization',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/localization/index.vue'),
  },
  {
    path: '/super/console',
    name: 'super-console',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/console/index.vue'),
  },
  {
    path: '/super/custom-report',
    name: 'super-custom-report',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/custom-report/index.vue'),
  },
  {
    path: '/super/lang-score-calculator',
    name: 'super-lang-score-calculator',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/lang-calculator/index.vue'),
  },
  {
    path: '/super/progress-report',
    name: 'super-progress-report',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/progress-report/index.vue'),
  },
  {
    path: '/super/schedule-sandbox',
    name: 'super-schedule-sandbox',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/sandbox/la-schdeule.vue'),
  },
  {
    path: '/super/control-system',
    name: 'notification-control-system',
    meta: {
      middleware: [isAuthenticated, adminUsersOnly],
    },
    component: () => import('@/views/super/control-system/index.vue'),
  },
]
