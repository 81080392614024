import axios from 'axios'
import { GEC_ML_END_POINT } from '@/const/chatbot'

const streamAudio = (text, endpoint = '/api/tts-ai-version', onPlaying = null, onComplete = null) => {
  const audioStream = new Audio(`${endpoint}?text=${text}`)
  audioStream
    .play()
    .then(() => {
      if (typeof onPlaying === 'function') {
        onPlaying() // Call the provided callback when audio starts playing
      }
    })
    .catch(error => console.error('Error playing audio:', error))
  audioStream.addEventListener('ended', () => {
    if (typeof onComplete === 'function') {
      onComplete(true) // Call the provided callback after audio ends
    }
  })
  return {
    audioStream,
    stop() {
      audioStream.pause() // Stop the audio playback
      audioStream.currentTime = 0 // Reset playback to the beginning
    },
  }
}

const streamAudioFromGecMl = async ({
  text, mainPoint, language, voice, history,
}, onPlaying = null, onComplete = null) => {
  try {
    const endpoint = `${GEC_ML_END_POINT}/common/tts/stream`
    const getCacheKey = await axios.post(endpoint, {
      prompt: '-',
      input: text,
      main_points: mainPoint,
      language,
      voice,
      history,
    })
    const cacheKey = getCacheKey.data.data.cache_key
    // TODO: Handle Stream Error
    const audioStream = new Audio(`${endpoint}?prompt=${cacheKey}`)
    audioStream
      .play()
      .then(() => {
        if (typeof onPlaying === 'function') {
          onPlaying() // Call the provided callback when audio starts playing
        }
      })
      .catch(error => {
        if (typeof onComplete === 'function') {
          onComplete({
            success: false,
            error,
            cacheKey,
          })
        }
        throw error
      })
    audioStream.addEventListener('ended', () => {
      if (typeof onComplete === 'function') {
        onComplete({
          success: true,
          error: null,
          cacheKey,
        }) // Call the provided callback after audio ends
      }
    })
    return {
      audioStream,
      stop() {
        audioStream.pause() // Stop the audio  playback
        audioStream.currentTime = 0 // Reset playback to the beginning
      },
    }
  } catch (error) {
    console.error(error)
    onComplete({
      success: false,
      error,
    })
    return null
  }
}

const streamBaseAudio = async ({
  input, prompt, language, voice, history = [],
}, onPlaying = null, onComplete = null) => {
  try {
    const endpoint = `${GEC_ML_END_POINT}/common/tts/stream`
    const getCacheKey = await axios.post(endpoint, {
      prompt,
      input,
      language,
      voice,
      history,
    })
    const cacheKey = getCacheKey.data.data.cache_key
    const audioStream = new Audio(`${endpoint}?prompt=${cacheKey}`)
    audioStream
      .play()
      .then(() => {
        if (typeof onPlaying === 'function') {
          onPlaying() // Call the provided callback when audio starts playing
        }
      })
      .catch(error => { throw new Error(error) })
    audioStream.addEventListener('ended', () => {
      if (typeof onComplete === 'function') {
        onComplete({
          success: true,
          error: null,
          cacheKey,
        }) // Call the provided callback after audio ends
      }
    })
    return {
      audioStream,
      stop() {
        audioStream.pause() // Stop the audio  playback
        audioStream.currentTime = 0 // Reset playback to the beginning
      },
    }
  } catch (error) {
    console.error(error)
    onComplete({
      success: false,
      error,
    })
    return null
  }
}

export {
  streamAudio,
  streamBaseAudio,
  streamAudioFromGecMl,
}
